import { NotificationClient } from "@/lib/clients/notificationClient";
import { NotificationSummary, Notification } from "@/lib/models";
import { ActionContext, ActionTree, GetterTree, MutationTree, Store } from "vuex";

const agentClient = new NotificationClient();

export interface AgentState {
  notificationList: Notification[];
  notificationSummary: NotificationSummary | null;
}

type Getters = {
  notificationList(state: AgentState): Notification[];
  notificationSummary(state: AgentState): NotificationSummary | null;
};

const getters: GetterTree<AgentState, {}> & Getters = {
  notificationList(state) {
    return state.notificationList;
  },

  notificationSummary(state) {
    return state.notificationSummary;
  },
};

type Mutations = {
  setNotificationList(state: AgentState, payload: Notification[]): void;
  setNotificationSummary(state: AgentState, payload: NotificationSummary): void;
};

const mutations: MutationTree<AgentState> & Mutations = {
  setNotificationList(state, payload) {
    return (state.notificationList = payload);
  },

  setNotificationSummary(state, payload) {
    return (state.notificationSummary = payload);
  },
};

type GetNotificationsSummaryPayload = {
  notificationGroup: string;
};

type ReadNotificationsPayload = {
  notificationIds: string[];
};

type ListNotificationsPayload = {
  notificationGroup: string;
  lastKey?: string;
  limit: number;
};

type Actions<S> = {
  getNotificationSummary(
    this: Store<{}>,
    injectee: ActionContext<AgentState, {}>,
    payload: GetNotificationsSummaryPayload,
  ): Promise<NotificationSummary>;

  readNotifications(
    this: Store<{}>,
    injectee: ActionContext<AgentState, {}>,
    payload: ReadNotificationsPayload
  ): Promise<NotificationSummary>;

  listNotifications(
    this: Store<{}>,
    injectee: ActionContext<AgentState, {}>,
    payload: ListNotificationsPayload
  ): Promise<void>;
};

const actions: ActionTree<AgentState, {}> & Actions<AgentState> = {
  async getNotificationSummary({ commit }, payload) {
    const summary = await agentClient.getNotificationSummary(payload);
    commit("setNotificationSummary", summary);

    return summary;
  },

  async readNotifications({ commit }, payload) {
    const summary = await agentClient.readNotifications(payload);
    commit("setNotificationSummary", summary);

    return summary;
  },

  async listNotifications({ commit }, payload) {
    const notification = await agentClient.listNotifications(payload);
    commit("setNotificationList", notification.items);

    return notification;
  },
};

const state: AgentState = {
  notificationList: [],
  notificationSummary: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
