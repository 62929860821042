import gql from "graphql-tag";
import GraphQLClient, { RequestParam } from "../api";
import { NotificationList, NotificationListInput, NotificationSummary } from "../models/notification";

interface NotificationSummaryInput {
  notificationGroup: string;
}

interface ReadNotificationsInput {
  notificationGroup: string;
  notificationIds: string[];
}

interface INotificationClient {
  unreadCount: number;
  getNotificationSummary: (input: NotificationSummaryInput) => Promise<NotificationSummary>;
  listNotifications: (input: NotificationListInput) => Promise<NotificationList>;
  readNotifications: (input: ReadNotificationsInput) => Promise<NotificationSummary>;
}

export class NotificationClient implements INotificationClient {
  public unreadCount = 0;

  public async readNotifications(input) {
    const params: RequestParam = {
      query: readNotificationsMutation,
      variables: {
        input,
      },
    };

    const { readNotifications } = await GraphQLClient.request(params);
    return readNotifications;
  }

  public async listNotifications(input: NotificationListInput) {
    const params: RequestParam = {
      query: listNotificationsQuery,
      variables: {
        input,
      },
    };

    const { notifications } = await GraphQLClient.request(params);
    return notifications;
  }

  public async getNotificationSummary(input: NotificationSummaryInput) {
    const params: RequestParam = {
      query: notificationsSummaryQuery,
      variables: {
        input,
      },
    };
    const { notificationSummary } = await GraphQLClient.request(params);
    return notificationSummary;
  }
}

const listNotificationsQuery = gql`
  query Notifications($input: NotificationListInput!) {
    notifications(input: $input) {
      items {
        notificationId
        message
        isRead
        iconUrl
        createdAt
      }
      lastKey
    }
  }
`;

const notificationsSummaryQuery = gql`
  query NotificationsSummary($input: NotificationSummaryGetInput!) {
    notificationSummary(input: $input) {
      unreadItemCount
    }
  }
`;

const readNotificationsMutation = gql`
  mutation ReadNotifications($input: NotificationSummaryReadInput!) {
    readNotifications(input: $input) {
      userId
      unreadItemCount
      notificationGroup
      createdAt
      updatedAt
    }
  }
`;
