import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import RootState from "./state";

import userModule from "./modules/user";
import novelModule from "./modules/novel";
import novelThemeModule from "./modules/novelTheme";
import manuscriptModule from "./modules/manuscript";
import memoModule from "./modules/memo";
import sharedLinkModule from "./modules/sharedLink";
import plotModule from "./modules/plot";
import characterModule from "./modules/character";
import worldViewModule from "./modules/worldView";
import timelineModule from "./modules/timeline";
import materialModule from "./modules/material";
import nolaNovelModule from "./modules/nolaNovel";
import correlationModule from "./modules/correlation";
import generalModule from "./modules/general";
import proofreadingModule from "./modules/proofreading";
import writingDataModule from "./modules/writingData";
import nolaContestModule from "./modules/nolaContest";
import manuscriptAnalysisModule from "./modules/manuscriptAnalysis";
import notificationModule from "./modules/notification";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store<RootState>({
  state: {},
  modules: {
    userModule,
    novelModule,
    novelThemeModule,
    manuscriptModule,
    memoModule,
    sharedLinkModule,
    plotModule,
    characterModule,
    worldViewModule,
    timelineModule,
    materialModule,
    nolaNovelModule,
    correlationModule,
    generalModule,
    proofreadingModule,
    writingDataModule,
    nolaContestModule,
    manuscriptAnalysisModule,
    notificationModule,
  },
  strict: debug,
  plugins: [
    // TODO: 今後ログアウトしたときを考慮すること
    createPersistedState({ storage: window.sessionStorage }),
  ],
});
